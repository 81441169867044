import { SearchGameList } from '../pages/Minigame/List';

function AdminMinigameSearchRoute() {
  return <SearchGameList />;
}

export const Component = AdminMinigameSearchRoute;

export function clientLoader() {
  return null;
}
